import React, {useState, useEffect} from 'react'
import {Canvas} from './components/Canvas'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {StatisticsWidget6} from './components/StatisticsWidget6'
import {getEmployeeHeader} from './components/CRUD'
import Cookies from 'universal-cookie'
//import {Card5} from './components/Card5'
//import Box from '@mui/material/Box';

const cookies = new Cookies()

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Employee',
    path: '/employee-manager/register',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function EmployeeRegister() {
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = React.useState('')
  const [assigned, setAssigned] = React.useState('')
  const [unassigned, setUnassigned] = React.useState('')

  useEffect(() => {
    displayData()
  }, [])


  const displayData = () => {
    setLoading(true)
    getEmployeeHeader(cookies.get('user_id'), cookies.get('employee_id'))
      .then((payload) => {
        setLoading(false)
        //console.log(payload.data.rows)
        const {rows} = payload.data
        const formattedData = rows.map((dat: any) => {
          return {
            id: parseInt(dat.id),
            total_employees: dat.total_employees,
            assigned_employees: dat.assigned_employees,
            last_name: dat.unassigned_employees,
          }
        })
        setTotal(rows[0].total_employees)
        setAssigned(rows[0].assigned_employees)
        setUnassigned(rows[0].unassigned_employees)
        //console.log(rows[0].collected)
        //setRows(formattedData)
        setTimeout(displayData, 3000)
      })
      .catch(() => {
        setLoading(false)
      })
    /*values.communications.email = data.communications.email
      values.communications.phone = data.communications.phone
      values.allowMarketing = data.allowMarketing
      const updatedData = Object.assign(data, values)
      setData(updatedData)*/
    setLoading(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Employee Registry</PageTitle>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      ></div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title='Complete'
            description='Total Employees'
            progress={total}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='warning'
            title='Of Total'
            description='Assigned Employees'
            progress={assigned}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='danger'
            title='Of Total'
            description='Unassigned Employees'
            progress={unassigned}
          />
        </div>
      </div>

      <Canvas />
    </>
  )
}
