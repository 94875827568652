import * as React from 'react'
import {useState, useEffect} from 'react'
import Box from '@mui/material/Box'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import {addServices, editServices, getServices} from './CRUD'
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRenderCellParams,
  GridPreProcessEditCellProps,
  GridCellParams,
  DataGrid,
  GridColumns,
  GridRowParams,
  GridToolbarExport,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid'
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
  randomId,
} from '@mui/x-data-grid-generator'

interface Services {
  id: string
  user_id: string
  name: string
  amount: string
  status: string
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

var initialRows: GridRowsProp = [
  {
    id: randomId(),
    user_id: '10',
    name: 'Loading...',
    amount: '888888888',
    status: 'enabled',
  },
]

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'KES',
})

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
  const {setRows, setRowModesModel} = props

  const handleClick = () => {
    const id = randomId()
    setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
    }))
  }

  return (
    <GridToolbarContainer>
      <Button className='btn btn-primary' startIcon={<AddIcon />} onClick={handleClick}>
        Add service
      </Button>
    </GridToolbarContainer>
  )
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState(initialRows)
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    displayData()
  }, [])

  const displayData = () => {
    setLoading(true)
    getServices('10,')
      .then((payload) => {
        setLoading(false)
        //console.log(payload.data.rows)
        setRows(payload.data.rows)
        setTimeout(displayData, 50000)
      })
      .catch(() => {
        setLoading(false)
      })
    /*values.communications.email = data.communications.email
      values.communications.phone = data.communications.phone
      values.allowMarketing = data.allowMarketing
      const updatedData = Object.assign(data, values)
      setData(updatedData)*/
    setLoading(false)
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}})
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}})
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id))
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    setLoading(true)
    if (newRow.isNew) {
      addServices(newRow.id, newRow.user_id, newRow.name, newRow.amount, newRow.status)
        .then(({data: {api_token}}) => {
          getServices('10,')
          .then((payload) => {
            //setLoading(false)
            //console.log(payload.data.rows)
            setRows(payload.data.rows)
            setLoading(false)
          })
          .catch(() => {
            //setLoading(false)
          })
    
        //setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
      setLoading(false)
    } else {
      editServices(newRow.id, newRow.user_id, newRow.name, newRow.amount, newRow.status)
        .then(({data: {api_token}}) => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
      setLoading(false)
    }
    const updatedRow = {...newRow, isNew: false}
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    //console.log(newRow)
    return updatedRow
  }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Service Name',
      width: 220,
      headerClassName: 'column_style',
      cellClassName: 'row_style',
      headerAlign: 'center',
      align: 'center',
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length < 3
        return {...params.props, error: hasError}
      },
    },
    {
      field: 'amount',
      headerName: 'Service Amount',
      type: 'number',
      width: 220,
      align: 'center',
      valueFormatter: ({value}) => currencyFormatter.format(value),
      headerClassName: 'column_style',
      cellClassName: 'row_style',
      headerAlign: 'center',
      editable: true,
      /*preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value!.length < 3
        return {...params.props, error: hasError}
      },*/
    },

    {
      field: 'status',
      headerName: 'Service Availability',
      type: 'singleSelect',
      width: 220,
      align: 'center',
      headerClassName: 'column_style',
      headerAlign: 'center',
      valueOptions: [{value: 'enabled', label: 'Enabled'}, {value: 'disabled', label: 'Disabled'}],
      editable: true,
      cellClassName: (params: GridCellParams<string>) => {
        //console.log('Params Value = ')
        if (params.value == null) {
          return 'row_style'
        }

        return clsx('cell_style', {
          enabled: params.value === 'enabled',
          disabled: params.value === 'disabled',
        })
      },
     /* valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );
  
        return option.label;
      },*/
      /*preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value!.length < 3
        return {...params.props, error: hasError}
      },*/
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      headerClassName: 'column_style',
      cellClassName: 'actions row_style',
      getActions: ({id}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='row_style'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
        ]
      },
    },
  ]

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .column_style': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
          '&:hover': {
            backgroundColor: '#151521',
            color: '#ffffff',
            fontWeight: '600',
            fontSize: '18px',
          },
        },
        '& .row_style': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#151521',
            color: '#ffffff',
            fontWeight: '600',
            fontSize: '18px',
          },
        },
        '& .cell_style.enabled': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#70c927 !important',
          fontWeight: '600',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#151521',
            color: '#ffffff',
            fontWeight: '600',
            fontSize: '18px',
          },
        },
        '& .cell_style.disabled': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#f03c4c !important',
          fontWeight: '600',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#151521',
            color: '#ffffff',
            fontWeight: '600',
            fontSize: '18px',
          },
        },
        '&>.MuiDataGrid-main': {
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            border: `1px solid '#151521'`,
          },
        },
        '& .MuiTablePagination-displayedRows': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
        '& .MuiSvgIcon-root': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
        '& .MuiTablePagination-selectLabel': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
        '& .MuiDataGrid-cell': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)!important',
          color: '#ffffff',
          fontWeight: '600',
          borderColor: '#323248',
          outlineColor: '#323248',
        },
        '& .MuiDataGrid-selectedRowCount': {
          backgroundColor: 'rgba(30, 30, 49, 0.0)!important',
          color: '#ffffff!important',
          fontWeight: '600',
          fontSize: '18px',
        },
        '& .MuiDataGrid-root': {
          outline: 'none !important',
          border: 'none !important',
        },

        '& .actions': {
          color: '#ffffff',
        },
        '& .textPrimary': {
          color: '#ffffff',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {setRows, setRowModesModel},
        }}
        experimentalFeatures={{newEditingApi: true}}
      />
    </Box>
  )
}
