import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_BODY_URL = `${API_URL}/get_arrivals`
export const HEADER_BODY_URL = `${API_URL}/get_arrivals_header`


export function getArrivals(user_id: string, date_inner: string) {
  return axios.post(GET_BODY_URL, {
    user_id, date_inner
  })
}

export function getArrivalsHeader(user_id: string, date_inner: string) {
  return axios.post(HEADER_BODY_URL, {
    user_id, date_inner
  })
}



