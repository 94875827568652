import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_EMPLOYEE_URL = `${API_URL}/add_employee`
export const GET_EMPLOYEES_URL = `${API_URL}/get_employees`
export const GET_EMPLOYEE_URL = `${API_URL}/get_employee`
export const GET_TOTALS_URL = `${API_URL}/get_employee_totals`
export const EDIT_EMPLOYEE_URL = `${API_URL}/edit_employee`

export function getEmployees(user_id: string, quest: string) {
  return axios.post(GET_EMPLOYEES_URL, {
    user_id, quest,
  })
}
export function getEmployee(user_id: string, employee_id: string) {
  return axios.post(GET_EMPLOYEE_URL, {
    user_id, employee_id,
  })
}

export function getEmployeeHeader(user_id: string, employee_id: string) {
  return axios.post(GET_TOTALS_URL, {
    user_id, employee_id,
  })
}

export function addEmployee(
  id: string,
  user_id: string,
  surname: string,
  first_name: string,
  gender: string,
  id_no: string,
) {
  return axios.post(ADD_EMPLOYEE_URL, {
    id,
    user_id,
    surname,
    first_name,
    gender,
    id_no,
  })
}

export function editEmployee(
  id: string,
  user_id: string,
  surname: string,
  first_name: string,
  gender: string,
  id_no: string,
) {
  return axios.post(EDIT_EMPLOYEE_URL, {
    id,
    user_id,
    surname,
    first_name,
    gender,
    id_no,
  })
}

