import React from 'react'
import {ProfileDetails} from './components/ProfileDetails'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Customers',
      path: '/customers/view-current-customers',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

export function AddBody() {
  return (
    <>
     <PageTitle breadcrumbs={profileBreadCrumbs}>Add Body</PageTitle>
      <ProfileDetails />
    </>
  )
}
