import React, {useState, useEffect} from 'react'
import {Canvas} from './components/Canvas'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {StatisticsWidget6} from './components/StatisticsWidget6'
import {getOverduesHeader} from './components/CRUD'
import Cookies from 'universal-cookie'
//import {Card5} from './components/Card5'
//import Box from '@mui/material/Box';

const cookies = new Cookies()

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Overdue Payments',
    path: '/dashboard/overdue-payments',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function OverduePayments() {
  const [loading, setLoading] = useState(false)
  const [collected, setCollected] = React.useState('')
  const [pending, setPending] = React.useState('')
  const [unpaid, setUnpaid] = React.useState('')

  useEffect(() => {
    displayData()
  }, [])


  const displayData = () => {
    setLoading(true)
    getOverduesHeader('10,', cookies.get('date_outer'))
      .then((payload) => {
        setLoading(false)
        //console.log(payload.data.rows)
        const {rows} = payload.data
        const formattedData = rows.map((dat: any) => {
          return {
            id: parseInt(dat.id),
            admission_number: dat.admission_number,
            first_name: dat.first_name,
            last_name: dat.last_name,
            //mobile: dat.mobile,
            date_out: dat.date_out,
            //payment_status: paymentStat(dat.balance),
            status: dat.status,
            balance: parseInt(dat.balance),
          }
        })
        setCollected(rows[0].collected)
        setPending(rows[0].pending)
        setUnpaid(rows[0].unpaid)
        console.log(rows[0].collected)
        //setRows(formattedData)
        setTimeout(displayData, 3000)
      })
      .catch(() => {
        setLoading(false)
      })
    /*values.communications.email = data.communications.email
      values.communications.phone = data.communications.phone
      values.allowMarketing = data.allowMarketing
      const updatedData = Object.assign(data, values)
      setData(updatedData)*/
    setLoading(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Overdue</PageTitle>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      ></div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title='Complete'
            description='Collected Bodies'
            progress={collected}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='warning'
            title='Of Total'
            description='Pending Collection'
            progress={pending}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='danger'
            title='Of Total'
            description='Collected Unpaid'
            progress={unpaid}
          />
        </div>
      </div>

      <Canvas />
    </>
  )
}
