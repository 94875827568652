import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_BODY_URL = `${API_URL}/add_body`

export function addBody(
  admission_number: string,
  first_name: string,
  last_name: string,
  date_in: string,
  date_out: string,
  location: string,
  transporter: string,
  disposed_to: string,
  invoice_number: string,
  receipt_number: string,
  payment_status: string,
  permit_number: string
) {
  return axios.post(ADD_BODY_URL, {
    admission_number,
    first_name,
    last_name,
    date_in,
    date_out,
    location,
    transporter,
    disposed_to,
    invoice_number,
    receipt_number,
    payment_status,
    permit_number,
  })
}
