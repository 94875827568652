/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MONITOR</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/dashboard'
        title='Dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      >
        {parseInt(cookies.get('user_level')) === 5 && (
          <AsideMenuItem
            to='/dashboard/departures-view'
            title='Schedule'
            icon='/media/icons/duotune/arrows/arr024.svg'
          />
        )}
        {parseInt(cookies.get('user_level')) <= 4 && (
          <AsideMenuItem
            to='/dashboard/departures'
            title='Personal'
            icon='/media/icons/duotune/arrows/arr024.svg'
          />
        )}
        {parseInt(cookies.get('user_level')) <= 4 && (
          <AsideMenuItem
            to='/dashboard/departures'
            title='Inter-Company'
            icon='/media/icons/duotune/arrows/arr024.svg'
          />
        )}
        {parseInt(cookies.get('user_level')) <= 4 && (
          <AsideMenuItem
            to='/dashboard/departures'
            title='HR'
            icon='/media/icons/duotune/arrows/arr024.svg'
          />
        )}
        {/*<AsideMenuItem
          to='/dashboard/overstays'
          title='Overstays'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/dashboard/overdue-payments'
          title='Overdue Payments'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />temp*/}
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>COMPANY</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/company'
        title='Company Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/company-manager/register'
          title='Company Registry'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>EMPLOYEE</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/employee'
        title='Employee Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/employee-manager/register'
          title='Employee Registry'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>LEAVE</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/leave'
        title='Leave Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      ></AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ATTENDANCE</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/attendance'
        title='Attendance Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      ></AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PAYROLL</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/payroll'
        title='Payroll Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      ></AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ICT</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/user'
        title='User Manager'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/users/view-users'
          title='Users'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
      </AsideMenuItemWithSub>

      {/* {parseInt(cookies.get('user_level')) <= 4 && (<AsideMenuItemWithSub
        to='/customers'
        title='Customers'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-app-indicator'
      >
        {/*<AsideMenuItem
          to='/customers/add-body'
          title='Add Body'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/customers/add-customer'
          title='Add Customer'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />*
        {(parseInt(cookies.get('user_level')) <= 4) && (<AsideMenuItem
          to='/customers/view-current-customers'
          title='Current Customers'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />)}
        {(parseInt(cookies.get('user_level')) <= 1) && (<AsideMenuItem
          to='/customers/all-customers'
          title='All Customers'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />)}
        {/*<AsideMenuItem
          to='/customer/meta/body-details'
          title='Body MetaData'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />*
      </AsideMenuItemWithSub>)}

      {parseInt(cookies.get('user_level')) <= 3 && (<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Back Office</span>
        </div>
      </div>)}

      {(parseInt(cookies.get('user_level')) <= 3) && (<AsideMenuItemWithSub
        to='/packages'
        title='Packages'
        icon='/media/icons/duotune/coding/cod002.svg'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/packages/services'
          title='Services'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/packages/hearse'
          title='Hearse'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/packages/casket'
          title='Casket'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/packages/flowers'
          title='Flowers'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        {/*temp<AsideMenuItem
          to='/packages/add-package'
          title='Add Package'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/packages/view-packages'
          title='View Packages'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/packages/receipt-designer'
          title='Receipt Designer'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />temp*
      </AsideMenuItemWithSub>)}

      {(parseInt(cookies.get('user_level')) <= 3) && (<AsideMenuItemWithSub
        to='/payments'
        title='Payments'
        icon='/media/icons/duotune/finance/fin007.svg'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/payments/payments-api-buffer'
          title='Payments API Buffer'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/payments/pending-api-payments'
          title='Pending API Payments'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
      </AsideMenuItemWithSub>)}

      {parseInt(cookies.get('user_level')) <= 1 && (<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
        </div>
      </div>)}

      {parseInt(cookies.get('user_level')) <= 1 && (
        <AsideMenuItemWithSub
          to='/users'
          title='Users'
          icon='/media/icons/duotune/general/gen019.svg'
          fontIcon='bi-app-indicator'
        >
          {/*temp} <AsideMenuItem
          to='/users/add-user'
          title='Add User'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />temp*
          <AsideMenuItem
            to='/users/view-users'
            title='User Manager'
            icon='/media/icons/duotune/arrows/arr024.svg'
          />
        </AsideMenuItemWithSub>
      )}

      {parseInt(cookies.get('user_level')) <= 1 && (
        <AsideMenuItemWithSub
          to='/reports'
          title='Reports'
          icon='/media/icons/duotune/graphs/gra012.svg'
          fontIcon='bi-app-indicator'
        >
          {/*temp<AsideMenuItem
          to='/reports/arrivals-report'
          title='Arrivals Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/departures-report'
          title='Departures Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/daily-reports'
          title='Daily Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/weekly-reports'
          title='Weekly Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/monthly-reports'
          title='Monthly Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/yearly-reports'
          title='Yearly Reports'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />
        <AsideMenuItem
          to='/reports/audit-logs'
          title='Audit Logs'
          icon='/media/icons/duotune/arrows/arr024.svg'
        />temp*
        </AsideMenuItemWithSub>
      )}

      {/*}  
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
      */}
    </>
  )
}
