import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
//import FormHelperText from '@mui/material/FormHelperText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor:'#1b1b29',
      color:'#92929F',
    },
  },
};


type Props = {
  names: string[],
  inputLabel: string
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultipleSelectChip: React.FC<Props> = ({names, inputLabel}) =>  {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl variant='outlined' sx={{ m: 0, width: 1,  }}>
        <InputLabel sx={{  color: '#92929F', fontWeight: 'bold', fontSize: 14 }} id="demo-multiple-chip-label">{inputLabel}</InputLabel>
        <Select sx={{ color: '#1b1b29', backgroundColor: '#1b1b29', borderColor: '#1b1b29', borderRadius: 1, border: 1 }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput  id="select-multiple-chip" label="Select Service" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderColor: '#1b1b29' }}>
              {selected.map((value) => (
                <Chip sx={{ backgroundColor: '#3699ff', color: 'white', fontWeight: 'bold', fontSize: 14 }} key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {/*<FormHelperText sx={{ color: '#92929F'}}>Required</FormHelperText>*/}
      </FormControl>
    </div>
  );
}

export {MultipleSelectChip}
