import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
/*import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../../modules/accounts/components/settings/SettingsModel'*/
import * as Yup from 'yup'
import {useFormik, FormikHelpers} from 'formik'
import {addCasket} from './CRUD'
import BasicEditingGrid from './DataTable'



const Canvas: React.FC = () => {


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_add_body'
        aria-expanded='true'
        aria-controls='kt_account_add_body'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Registered Caskets</h3>
        </div>
      </div>

      <div id='kt_account_add_body' className='collapse show'>
        <form  noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              

              <div className='row mb-6'>
                <BasicEditingGrid />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            
          </div>
        </form>
      </div>
    </div>
  )
}

export {Canvas}
