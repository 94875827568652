import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_COMPANY_URL = `${API_URL}/add_company`
export const GET_COMPANIES_URL = `${API_URL}/get_companies`
export const GET_COMPANY_URL = `${API_URL}/get_company`
export const GET_TOTALS_URL = `${API_URL}/get_company_totals`
export const EDIT_COMPANY_URL = `${API_URL}/edit_company`

export function getCompanies(user_id: string, quest: string) {
  return axios.post(GET_COMPANIES_URL, {
    user_id,
    quest,
  })
}
export function getCompany(user_id: string, company_id: string) {
  return axios.post(GET_COMPANY_URL, {
    user_id,
    company_id,
  })
}

export function getCompaniesHeader(user_id: string, company_id: string) {
  return axios.post(GET_TOTALS_URL, {
    user_id,
    company_id,
  })
}

export function addCompany(id: string, user_id: string, pin: string, company_name: string, incorporation_id: string) {
  return axios.post(ADD_COMPANY_URL, {
    id,
    user_id,
    pin,
    company_name,
    incorporation_id,
  })
}

export function editCompany(id: string, user_id: string, pin: string, company_name: string, incorporation_id: string) {
  return axios.post(EDIT_COMPANY_URL, {
    id,
    user_id,
    pin,
    company_name,
    incorporation_id,
  })
}
