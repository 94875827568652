import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Customers',
    path: '/customers/view-current-customers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function ViewCustomer() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Customer Details</PageTitle>
    </>
  )
}
