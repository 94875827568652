import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_BODY_URL = `${API_URL}/add_user`
export const EDIT_BODY_URL = `${API_URL}/edit_user`
export const GET_BODY_URL = `${API_URL}/get_users`

export function getUsers(body_id: string) {
  return axios.post(GET_BODY_URL, {
    body_id,
  })
}


export function addUser(
  id: string,
  user_id: string,
  first_name: string,
  last_name: string,
  email_address: string,
  password: string,
  level: string,
  status: string
) {
  return axios.post(ADD_BODY_URL, {
    id,
    user_id,
    first_name,
    last_name,
    email_address,
    password,
    level,
    status,
  })
}

export function editUser(
  id: string,
  user_id: string,
  first_name: string,
  last_name: string,
  email_address: string,
  password: string,
  level: string,
  status: string
) {
  return axios.post(EDIT_BODY_URL, {
    id,
    user_id,
    first_name,
    last_name,
    email_address,
    password,
    level,
    status,
  })
}
