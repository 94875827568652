import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_BODY_URL = `${API_URL}/get_departures`
export const HEADER_BODY_URL = `${API_URL}/get_departures_header`


export function getDepartures(user_id: string, date_outer: string) {
  return axios.post(GET_BODY_URL, {
    user_id, date_outer
  })
}

export function getDeparturesHeader(user_id: string, date_outer: string) {
  return axios.post(HEADER_BODY_URL, {
    user_id, date_outer
  })
}



