/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const Toolbar1: FC = () => {
  const {classes} = useLayout()
  const navigate = useNavigate()

  const handleEmployee = () => {
    cookies.set('body_id', '', {path: '/'})
    cookies.set('body_value', 'false', {path: '/'})
    cookies.set('body_status', 'enabled', {path: '/'})
    navigate('/employee/meta/profile')
  }

  const handleCompany = () => {
    cookies.set('body_id', '', {path: '/'})
    cookies.set('body_value', 'false', {path: '/'})
    cookies.set('body_status', 'enabled', {path: '/'})
    navigate('/company/meta/profile')
  }
  const handleSubsidiary = () => {
    cookies.set('body_id', '', {path: '/'})
    cookies.set('body_value', 'false', {path: '/'})
    cookies.set('body_status', 'enabled', {path: '/'})
    navigate('/subsidiary/meta/profile')
  }

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />

        {/* begin::Actions */}
        <div className='d-flex align-items-center py-1'>
          {/* begin::Wrapper */}
          <div className='me-4'>
            {/* begin::Menu */}
            {/*<Link
              to='/customers/add-customer'
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {/*<KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />*/}
            {/*Add Customer
            </Link>*/}

            {/* end::Menu */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Button */}
          {/*<Link
            to='/customers/add-body'
            className='btn btn-sm btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_app'
            id='kt_toolbar_primary_button'
          >
            New Body
          </Link>*/}

          {/*parseInt(cookies.get('user_level')) <= 4 && (
            <button onClick={handleEmployee} className='btn btn-primary' disabled={false}>
              New Employee
            </button>
          )}
           {parseInt(cookies.get('user_level')) <= 4 && (
            <button onClick={handleCompany} className='btn btn-primary' disabled={false}>
              New Company
            </button>
          )}
            {parseInt(cookies.get('user_level')) <= 4 && (
            <button onClick={handleSubsidiary} className='btn btn-primary' disabled={false}>
              New Subsidiary
            </button>
          )*/}
          

          {/* end::Button */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
