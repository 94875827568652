import * as React from 'react'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box'
import * as Yup from 'yup'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField'
import dateFormat, {masks} from 'dateformat'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import {getEmployees, addEmployee, editEmployee} from './CRUD'
import Cookies from 'universal-cookie'
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridEditCellProps,
  GridRenderCellParams,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  useGridApiContext,
  GridPreProcessEditCellProps,
  GridCellParams,
  DataGrid,
  GridColumns,
  GridRowParams,
  GridToolbarExport,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid'
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
  randomId,
} from '@mui/x-data-grid-generator'

const cookies = new Cookies()
const now = new Date(dateFormat())

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 900,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    backgroundColor: '#1e1e2d',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    //backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#318fe8',
    backgroundColor: '#1c558b',
  },
}));

interface Body {
  id: string
  admission_number: string
  first_name: string
  last_name: string
  mobile: string
  date_in: string
  date_out: string
  payment_status: string
  balance: string
}

var amt = ''
let promiseTimeout: any
cookies.set('body_value', 'false', {path: '/'})

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton className='btn btn-lg btn-light-primary me-3' />
      <GridToolbarFilterButton className='btn btn-lg btn-light-primary me-3' />
      <GridToolbarDensitySelector className='btn btn-lg btn-light-primary me-3' />
      <GridToolbarExport className='btn btn-lg btn-light-primary me-3' />
    </GridToolbarContainer>
  )
}

const profileDetailsSchema = Yup.object().shape({
  services_id: Yup.string().required('Required'),
})

var initialRows: GridRowsProp = []

var initialDropdown: GridRowsProp = []

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'KES',
})

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
  const {setRows, setRowModesModel} = props

  const handleClick = () => {
    const id = randomId()
    setRows((oldRows) => [...oldRows, {id, services_id: '', age: '', isNew: true}])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: {mode: GridRowModes.Edit, fieldToFocus: 'id_no'},
    }))
  }

  return (
    <GridToolbarContainer>
      <Button className='btn btn-light-primary' startIcon={<AddIcon />} onClick={handleClick}>
        Add Employee
      </Button>
    </GridToolbarContainer>
  )
}

export default function FullFeaturedCrudGrid() {
  const navigate = useNavigate()
  const [rows, setRows] = React.useState(initialRows)
  const [dropdown, setDropdown] = React.useState(initialDropdown)
  const [dateInValue, setDateInValue] = React.useState<Date | null>(new Date())
  const [dateOutValue, setDateOutValue] = React.useState<Date | null>(new Date())
  const [datum, setDatum] = React.useState('')
  const keyStrokeTimeoutRef = React.useRef<any>()
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [loading, setLoading] = useState(false)

  const handleDropChange = (event: SelectChangeEvent) => {
    //setAge(event.target.value as string);
  }

  useEffect(() => {
    displayData()
  }, [])

  cookies.set('date_outer', dateInValue.toString(), {path: '/'})
  //console.log(dateInValue)
  //console.log(cookies.get('date_outer'))

  const paymentStat = (balance: string) => {
    if (parseInt(balance) > 0) {
      return 'disabled'
    } else {
      return 'enabled'
    }
  }
  const displayData = () => {
    setLoading(true)
    getEmployees(cookies.get('user_id'), datum)
      .then((payload) => {
        setLoading(false)
        //console.log(payload.data.rows)
        const {rows} = payload.data
        const formattedData = rows.map((dat: any) => {
          return {
            id: parseInt(dat.id),
            id_no: dat.id_no,
            surname: dat.surname,
            first_name: dat.first_name,
            gender: dat.gender,
            assigned: dat.assigned,
          }
        })
        //console.log(formattedData)
        setRows(formattedData)
        setTimeout(displayData, 5*60*1000)
      })
      .catch(() => {
        setLoading(false)
      })
    /*values.communications.email = data.communications.email
      values.communications.phone = data.communications.phone
      values.allowMarketing = data.allowMarketing
      const updatedData = Object.assign(data, values)
      setData(updatedData)*/
  }

  //console.log(rows)
  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true
    console.log('handleRowEditStart')
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true
    console.log('handleRowEditStop')
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}})
    console.log('handleEditClick')
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}})
    console.log('handleSaveClick')
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id))
    console.log('handleDeleteClick')
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const onEditCell = (params: any) =>
    new Promise<GridEditCellProps>((resolve) => {
      clearTimeout(promiseTimeout)
      clearTimeout(keyStrokeTimeoutRef.current)
      var found = dropdown.find((e) => e.value === params.props.value)
      //console.log(dropdown.value[params.props.value].amount)
      if (params.hasChanged) {
        amt = found.amount
        console.log(params)
        console.log(amt)
        console.log(dropdown)
      }

      // basic debouncing here
      keyStrokeTimeoutRef.current = setTimeout(async () => {
        try {
          resolve({...params.props, error: params.props.value === '3'})
        } catch (error) {
          console.error(error)
        }
      }, 500)
    })

  const CustomTypeEditComponent = (props: GridEditSingleSelectCellProps) => {
    const apiRef = useGridApiContext()

    const handleValueChange = () => {
      // var foundValue = dropdown.filter(obj=>obj.value===props.value);
      setTimeout(() => {
        console.log(amt)
        apiRef.current.setEditCellValue({
          id: props.id,
          field: 'amount',
          value: amt,
        })
      }, 1000)
    }

    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    setLoading(true)
    if (newRow.isNew) {
      addEmployee(
        newRow.id,
        cookies.get('user_id'),
        newRow.surname,
        newRow.first_name,
        newRow.gender,
        newRow.id_no
      )
        .then(({data: {api_token}}) => {
          getEmployees(cookies.get('user_id'), '')
            .then((payload) => {
              //setLoading(false)
              //console.log(payload.data.rows)
              const {rows} = payload.data
              const formattedData = rows.map((dat: any) => {
                return {
                  id: parseInt(dat.id),
                  id_no: dat.id_no,
                  surname: dat.surname,
                  first_name: dat.first_name,
                  gender: dat.gender,
                  assigned: dat.assigned,
                }
              })
              setRows(formattedData)
              setLoading(false)
            })
            .catch(() => {
              //setLoading(false)
            })

          //setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
      //setLoading(false)
    } else {
      editEmployee(
        newRow.id,
        cookies.get('user_id'),
        newRow.surname,
        newRow.first_name,
        newRow.gender,
        newRow.id_no
      )
        .then(({data: {api_token}}) => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
      //setLoading(false)
    }
    const updatedRow = {...newRow, isNew: false}
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    //console.log(newRow)
    return updatedRow
  }

  const checkoutOnClick = (id: GridRowId) => () => {
    cookies.set('employee_id', id, {path: '/'})
    navigate('/employee/meta/profile')
  }

  const columns: GridColumns = [
    {
      field: 'id_no',
      headerName: 'ID Number',
      type: 'string',
      flex: 1,
      minWidth: 100,
      headerClassName: 'column_style',
      cellClassName: 'row_style',
      headerAlign: 'center',
      align: 'center',
      editable: true,
    },
    {
      field: 'surname',
      headerName: 'Surname',
      type: 'string',
      flex: 1,
      minWidth: 100,
      headerClassName: 'column_style',
      cellClassName: 'row_style',
      headerAlign: 'center',
      align: 'center',
      editable: true,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      type: 'string',
      flex: 1,
      minWidth: 100,
      headerClassName: 'column_style',
      cellClassName: 'row_style',
      headerAlign: 'center',
      align: 'center',
      editable: true,
    },

    {
      field: 'gender',
      headerName: 'Gender',
      type: 'singleSelect',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerClassName: 'column_style',
      headerAlign: 'center',
      valueOptions: [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        {value: 'Other', label: 'Other'},
      ],
      editable: true,
      cellClassName: (params: GridCellParams<string>) => {
        //console.log('Params Value = ')
        if (params.value == null) {
          return 'row_style'
        }

        return clsx('cell_style', {
          male: params.value === 'Male',
          female: params.value === 'Female',
        })
      },
      valueFormatter: ({id: rowId, value, field, api}) => {
        const colDef = api.getColumn(field)
        const option = colDef.valueOptions.find(({value: optionValue}) => value === optionValue)

        if (option) {
          return option.label
        }
      },
      /*preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value!.length < 3
        return {...params.props, error: hasError}
      },*/
    },

    {
      field: 'assigned',
      headerName: 'Status',
      type: 'singleSelect',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerClassName: 'column_style',
      headerAlign: 'center',
      valueOptions: [
        {value: 'assigned', label: 'Assigned'},
        {value: 'unassigned', label: 'Unassigned'},
      ],
      editable: false,
      cellClassName: (params: GridCellParams<string>) => {
        //console.log('Params Value = ')
        if (params.value == null) {
          return 'row_style'
        }

        return clsx('cell_style', {
          enabled: params.value === 'assigned',
          disabled: params.value === 'unassigned',
        })
      },
      valueFormatter: ({id: rowId, value, field, api}) => {
        const colDef = api.getColumn(field)
        const option = colDef.valueOptions.find(({value: optionValue}) => value === optionValue)

        if (option) {
          return option.label
        }
      },
      /*preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value!.length < 3
        return {...params.props, error: hasError}
      },*/
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      hide: false,
      headerClassName: 'column_style',
      cellClassName: 'actions row_style',
      getActions: ({id}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        return [
         <Button
          sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
            variant='contained'
            className='btn btn-lg btn-light-primary'
            style={{marginLeft: 16}}
            onClick={checkoutOnClick(id)}
          >
            <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
            Manage
          </Button>,
        ]
      },
    },
  ]

  return (
    <Box
    sx={{
      height: 900,
      width: '100%',

      '& .MuiDataGrid-columnHeaders': {
        fontWeight: '1000 !important',
        borderBottomWidth: '5px !important',
        borderBottomStyle: 'solid !important',
        borderBottomColor: '#323248 !important',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700 !important',
      },
      '& .column_style': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab',
        fontWeight: '1000 !important',
        fontSize: '20px',
        '&:hover': {
          backgroundColor: '#212e48 !important',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '& .row_style': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#ffffff',
        fontWeight: '600',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#212e48 !important',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '& .MuiDataGrid-row': {
        boxShadow: 5,
        borderRadius: '3rem',
        margin: '15px 15px 0 0',
        padding: '0 0 0 0',
        backgroundColor: '#242436',
        transition: "transform 0.15s ease-in-out",
        '&:hover': {
          boxShadow: 15,
          backgroundColor: '#212e48 !important',
          transform: "scale3d(1.03, 1.03, 1)",
        },
      },
      '& .cell_style.enabled': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#70c927 !important',
        fontWeight: '600',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#212e48',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '& .cell_style.disabled': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#F64E60 !important',
        fontWeight: '600',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#212e48',
          color: '#F64E60',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '& .cell_style.male': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab !important',
        fontWeight: '600',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#212e48',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '& .cell_style.female': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab !important',
        fontWeight: '600',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#212e48',
          color: '#ffffff',
          fontWeight: '600',
          fontSize: '18px',
        },
      },
      '&>.MuiDataGrid-main': {
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          border: `1px solid '#151521'`,
        },
      },
      '& .MuiTablePagination-displayedRows': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab',
        fontWeight: '600',
        fontSize: '18px',
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab',
        fontWeight: '600',
        fontSize: '18px',
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab',
        fontWeight: '600',
        fontSize: '18px',
      },
      '& .MuiTablePagination-selectLabel': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)',
        color: '#9193ab',
        fontWeight: '600',
        fontSize: '18px',
      },
      '& .MuiDataGrid-cell': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)!important',
        color: '#9193ab',
        fontWeight: '600',
        borderColor: '#323248',
        outlineColor: '#323248',
        borderBottomWidth: '1px !important',
        borderBottomStyle: 'none !important',
        borderBottomColor: '#323248 !important',
      },
      '& .MuiDataGrid-selectedRowCount': {
        backgroundColor: 'rgba(30, 30, 49, 0.0)!important',
        color: '#9193ab!important',
        fontWeight: '600',
        fontSize: '18px',
      },
      '& .MuiDataGrid-root': {
        outline: 'none !important',
        border: 'none !important',
      },

      '& .MuiDataGrid-footerContainer': {
        borderTopWidth: '5px !important',
        borderTopStyle: 'solid !important',
        borderTopColor: '#323248 !important',
      },

      '& .btn': {
        fontWeight: '600',
        fontSize: '12px',
      },

      '& .actions': {
        color: '#9193ab',
      },
      '& .textPrimary': {
        color: '#9193ab',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: '2.4em',
        height: '2.4em',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        backgroundColor: '#212e48',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
        background: '#3699ff',
      },
    }}
    >
      {/*<div className='row mb-6'>
        <div className='row'>
          <Stack direction='row' justifyContent='end'>
            <div className='col-lg-4 fv-row'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DatePicker
                    disablePast
                    label='Date Out'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={dateInValue}
                    onChange={(newDateInValue) => {
                      setDateInValue(newDateInValue)
                      cookies.set('date_outer', dateInValue, {path: '/'})
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          svg: {color: '#92929F'},
                          input: {color: '#92929F'},
                          label: {color: '#92929F'},
                          backgroundColor: {color: '#1b1b29'},
                        }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </Stack>
        </div>
      </div>*/}

      <DataGrid
      sx={{
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        
      }}
        rows={rows}
        columns={columns}
        editMode='row'
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
          LoadingOverlay: BorderLinearProgress,
        }}
        loading = {loading}
        componentsProps={{
          toolbar: {setRows, setRowModesModel},
        }}
        experimentalFeatures={{newEditingApi: true, preventCommitWhileValidating: true}}
      />
    </Box>
  )
}

const StyledGridOverlay = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width='120' height='100' viewBox='0 0 184 152' aria-hidden focusable='false'>
        <g fill='none' fillRule='evenodd'>
          <g transform='translate(24 31.67)'>
            <ellipse className='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' />
            <path
              className='ant-empty-img-1'
              d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
            />
            <path
              className='ant-empty-img-2'
              d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
            />
            <path
              className='ant-empty-img-3'
              d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
            />
          </g>
          <path
            className='ant-empty-img-3'
            d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
          />
          <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
            <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
            <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
          </g>
        </g>
      </svg>
      <Box sx={{mt: 1}}>No Rows</Box>
    </StyledGridOverlay>
  )
}
