import React from "react";
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Packages',
      path: '/packages/view-packages',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
                  
export function EditPackage() {
    return <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Package</PageTitle>
    </>
}