import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
/*import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../../modules/accounts/components/settings/SettingsModel'*/
import * as Yup from 'yup'
import {useFormik, FormikHelpers} from 'formik'
import {addBody} from './CRUD'

interface Values {
  avatar: string
  admission_number: string
  first_name: string
  last_name: string
  date_in: string
  date_out: string
  location: string
  transporter: string
  disposed_to: string
  invoice_number: string
  receipt_number: string
  payment_status: string
  permit_number: string
}

const initialValues = {
  avatar: '/media/avatars/blank.png',
  admission_number: '',
  first_name: '',
  last_name: '',
  date_in: '',
  date_out: '',
  location: '',
  transporter: '',
  disposed_to: '',
  invoice_number: '',
  receipt_number: '',
  payment_status: '',
  permit_number: '',
}

const profileDetailsSchema = Yup.object().shape({
  admission_number: Yup.string().required('Admission Number is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  date_in: Yup.string().required('Date In is required'),
  date_out: Yup.string().required('Date Out is required'),
  location: Yup.string().required('Location is Required'),
  transporter: Yup.string().required('Transporter is required'),
  disposed_to: Yup.string().required('Disposed To is required'),
  invoice_number: Yup.string().required('Invoice Number is required'),
  receipt_number: Yup.string().required('Receipt Number is required'),
  payment_status: Yup.string().required('Payment Status is required'),
  permit_number: Yup.string().required('Permit Number is required'),
})

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<Values>(initialValues)
  const updateData = (fieldsToUpdate: Partial<Values>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  //const formik = useFormik<IProfileDetails>({
  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values: Values, {setStatus, setSubmitting}: FormikHelpers<Values>) => {
      setLoading(true)
      setTimeout(() => {
        addBody(
          values.admission_number,
          values.first_name,
          values.last_name,
          values.date_in,
          values.date_out,
          values.location,
          values.transporter,
          values.disposed_to,
          values.invoice_number,
          values.receipt_number,
          values.payment_status,
          values.permit_number
        )
          .then(({data: {api_token}}) => {
            setLoading(false)
            //dispatch(auth.actions.login(api_token))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Server Unavailable.')
          })
        /*values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)*/
        setLoading(false)
      }, 1000)
    },
  })



  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_add_body'
        aria-expanded='true'
        aria-controls='kt_account_add_body'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_add_body' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                  ></div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>ADM Nr</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('admission_number')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Admission Number'
                />
                {formik.touched.admission_number && formik.errors.admission_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.admission_number}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Deceased Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...formik.getFieldProps('first_name')}
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...formik.getFieldProps('last_name')}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'></label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...formik.getFieldProps('date_in')}
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Date In'
                    />
                    {formik.touched.date_in && formik.errors.date_in && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.date_in}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...formik.getFieldProps('date_out')}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Date Out'
                    />
                    {formik.touched.date_out && formik.errors.date_out && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.date_out}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Location</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('location')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Location'
                />
                {formik.touched.location && formik.errors.location && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.location}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Transporter</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('transporter')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Transporter'
                />
                {formik.touched.transporter && formik.errors.transporter && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.transporter}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Disposed To</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('disposed_to')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Disposed To'
                />
                {formik.touched.disposed_to && formik.errors.disposed_to && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.disposed_to}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Invoice Number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('invoice_number')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Invoice Number'
                />
                {formik.touched.invoice_number && formik.errors.invoice_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoice_number}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Receipt Number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('receipt_number')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Receipt Number'
                />
                {formik.touched.receipt_number && formik.errors.receipt_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.receipt_number}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Payment Status</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('payment_status')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Payment Status'
                />
                {formik.touched.payment_status && formik.errors.payment_status && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.payment_status}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Permit Number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('permit_number')}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Permit Number'
                />
                {formik.touched.permit_number && formik.errors.permit_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.permit_number}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add Body'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
