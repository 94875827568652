import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
//import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils";

import {AddBody} from '../pages/customers/addBody/AddBody'
import {AddCustomer} from '../pages/customers/addCustomer/AddCustomer'
import {AllCustomers} from '../pages/customers/allCustomers/AllCustomers'
import {CheckoutCustomer} from '../pages/customers/checkoutCustomer/CheckoutCustomer'
import {ReceivePayment} from '../pages/customers/receivePayment/ReceivePayment'
import {ViewCurrentCustomers} from '../pages/customers/viewCurrentCustomers/ViewCurrentCustomers'
import {ViewCustomer} from '../pages/customers/viewCustomer/ViewCustomer'
import {Arrivals} from '../pages/dashboard/arrivals/Arrivals'
import {Departures} from '../pages/dashboard/departures/Departures'
import {DeparturesView} from '../pages/dashboard/departuresView/Departures'
import {Overstays} from '../pages/dashboard/overstays/Overstays'
import {OverduePayments} from '../pages/dashboard/overduePayments/OverduePayments'
import {AddPackage} from '../pages/packages/addPackage/AddPackage'
import {Services} from '../pages/packages/services/Services'
import {Casket} from '../pages/packages/casket/Casket'
import {Hearse} from '../pages/packages/hearse/Hearse'
import {Flowers} from '../pages/packages/flowers/Flowers'
import {EditPackage} from '../pages/packages/editPackage/EditPackage'
import {EditChargeItem} from '../pages/packages/editChargeItem/EditChargeItem'
import {ReceiptDesigner} from '../pages/packages/receiptDesigner/ReceiptDesigner'
import {ViewPackages} from '../pages/packages/viewPackages/ViewPackages'
import {PaymentsAPIBuffer} from '../pages/payments/paymentsAPIBuffer/PaymentsAPIBuffer'
import {PendingAPIPayments} from '../pages/payments/pendingAPIPayments/PendingAPIPayments'
import {ArrivalsReport} from '../pages/reports/arrivals/ArrivalsReport'
import {DailyReports} from '../pages/reports/dailyReports/DailyReports'
import {WeeklyReports} from '../pages/reports/weeklyReports/WeeklyReports'
import {DeparturesReport} from '../pages/reports/departures/DeparturesReport'
import {MonthlyReports} from '../pages/reports/monthlyReports/MonthlyReports'
import {AuditLogs} from '../pages/reports/auditLogs/AuditLogs'
import {YearlyReports} from '../pages/reports/yearlyReports/YearlyReports'
import {ViewUsers} from '../pages/users/viewUsers/ViewUsers'
import {Details} from '../pages/customers/bodyMeta/details/Details'
import {DetailsView} from '../pages/customers/bodyMetaView/details/Details'
import {CustomerProfile} from '../pages/customers/bodyMeta/customer/CustomerProfile'
import {EmployeeRegister} from '../pages/employeeManager/employeeRegister/EmployeeRegister'
import {CompanyRegister} from '../pages/companyManager/companyRegister/CompanyRegister'
import Cookies from 'universal-cookie'
import {UserModel} from '../../app/modules/auth/models/UserModel'
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import {RootState} from '../../setup'
import {shallowEqual, useSelector} from 'react-redux'

const cookies = new Cookies()



const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const BodyMeta = lazy(() => import('../pages/customers/bodyMeta/BodyMeta'))
  const EmployeeMeta = lazy(() => import('../pages/employeeManager/employeeMeta/EmployeeMeta'))
  const CompanyMeta = lazy(() => import('../pages/companyManager/companyMeta/CompanyMeta'))
  const SubsidiaryMeta = lazy(() => import('../pages/companyManager/subsidiaryMeta/SubsidiaryMeta'))
  const BodyMetaView = lazy(() => import('../pages/customers/bodyMetaView/BodyMeta'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {parseInt(user.level) === 5 && (<Route path='auth/*' element={<Navigate to='/employee-manager/register' />} />)}
        {parseInt(user.level) <= 4 && (<Route path='auth/*' element={<Navigate to='/employee-manager/register' />} />)}
        {/* Pages */}
        {/*<Route path='dashboard' element={<DashboardWrapper />} />*/}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        
        <Route path='/customers/add-body' element={<AddBody />} />
        <Route path='/customers/add-customer' element={<AddCustomer />} />
        <Route path='/customers/all-customers' element={<AllCustomers />} />
        <Route path='/customers/checkout-customer' element={<CheckoutCustomer />} />
        <Route path='/customers/receive-payment' element={<ReceivePayment />} />
        <Route path='/customers/view-current-customers' element={<ViewCurrentCustomers />} />
        <Route path='/customers/view-customer' element={<ViewCustomer />} />
        <Route path='/dashboard/arrivals' element={<Arrivals />} />
        <Route path='/dashboard/departures' element={<Departures />} />
        <Route path='/dashboard/departures-view' element={<DeparturesView />} />
        <Route path='/dashboard/overstays' element={<Overstays />} />
        <Route path='/dashboard/overdue-payments' element={<OverduePayments />} />
        <Route path='/packages/add-package' element={<AddPackage />} />
        <Route path='/packages/services' element={<Services />} />
        <Route path='/packages/casket' element={<Casket />} />
        <Route path='/packages/hearse' element={<Hearse />} />
        <Route path='/packages/flowers' element={<Flowers />} />
        <Route path='/packages/edit-package' element={<EditPackage />} />
        <Route path='/packages/edit-charge-items' element={<EditChargeItem />} />
        <Route path='/packages/receipt-designer' element={<ReceiptDesigner />} />
        <Route path='/packages/view-packages' element={<ViewPackages />} />
        <Route path='/payments/payments-api-buffer' element={<PaymentsAPIBuffer />} />
        <Route path='/payments/pending-api-payments' element={<PendingAPIPayments />} />
        <Route path='/reports/arrivals-report' element={<ArrivalsReport />} />
        <Route path='/reports/daily-reports' element={<DailyReports />} />
        <Route path='/reports/weekly-reports' element={<WeeklyReports />} />
        <Route path='/reports/departures-report' element={<DeparturesReport />} />
        <Route path='/reports/monthly-reports' element={<MonthlyReports />} />
        <Route path='/reports/audit-logs' element={<AuditLogs />} />
        <Route path='/reports/yearly-reports' element={<YearlyReports />} />
        <Route path='/users/view-users' element={<ViewUsers />} />
        <Route path='/employee-manager/register' element={<EmployeeRegister />} />
        <Route path='/company-manager/register' element={<CompanyRegister />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='customer/meta/*'
          element={
            <SuspensedView>
              <BodyMeta />
            </SuspensedView>
          }
        />
        <Route
          path='customer/meta-view/*'
          element={
            <SuspensedView>
              <BodyMetaView />
            </SuspensedView>
          }
        />
        <Route
          path='employee/meta/*'
          element={
            <SuspensedView>
              <EmployeeMeta />
            </SuspensedView>
          }
        />
        <Route
          path='company/meta/*'
          element={
            <SuspensedView>
              <CompanyMeta />
            </SuspensedView>
          }
        />
        <Route
          path='subsidiary/meta/*'
          element={
            <SuspensedView>
              <SubsidiaryMeta />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
