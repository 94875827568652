import React from "react";
import {Canvas} from './components/Canvas'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Casket',
      path: '/packages/casket',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
                  
export function Casket() {
    return <>
    <PageTitle breadcrumbs={profileBreadCrumbs}>Casket</PageTitle>
    <Canvas />
    </>
}