import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_BODY_URL = `${API_URL}/add_flowers`
export const EDIT_BODY_URL = `${API_URL}/edit_flowers`
export const GET_BODY_URL = `${API_URL}/get_flowers`

export function getFlowers(user_id: string) {
  return axios.post(GET_BODY_URL, {
    user_id,
  })
}

export function addFlowers(
  id: string,
  user_id: string,
  name: string,
  amount: string,
  status: string
) {
  return axios.post(ADD_BODY_URL, {
    id,
    user_id,
    name,
    amount,
    status,
  })
}

export function editFlowers(
  id: string,
  user_id: string,
  name: string,
  amount: string,
  status: string
) {
  return axios.post(EDIT_BODY_URL, {
    id,
    user_id,
    name,
    amount,
    status,
  })
}
