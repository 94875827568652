import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_BODY_URL = `${API_URL}/get_overdues`
export const HEADER_BODY_URL = `${API_URL}/get_overdues_header`


export function getOverdues(user_id: string, date_outer: string) {
  return axios.post(GET_BODY_URL, {
    user_id, date_outer
  })
}

export function getOverduesHeader(user_id: string, date_outer: string) {
  return axios.post(HEADER_BODY_URL, {
    user_id, date_outer
  })
}



