import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {StatisticsWidget6} from './components/StatisticsWidget6'
import {ChartsWidget1} from './components/ChartsWidget1'
import {ChartsWidget6} from './components/ChartsWidget6'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/reports/arrivals-report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function ArrivalsReport() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Arrivals Report</PageTitle>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title='Of Total'
            description='Body Count'
            progress='76%'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='info'
            title='Of Total'
            description='Income'
            progress='76%'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='success'
            title='Of Total'
            description='Unpaid'
            progress='76%'
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-6'>
        <ChartsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
    </>
  )
}
