import React from "react";
import {TablesWidget13} from './components/TablesWidget13'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      path: '/reports/arrivals-report',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
                  
export function AuditLogs() {
    return <>
    <PageTitle breadcrumbs={profileBreadCrumbs}>Audit Logs</PageTitle>
    <TablesWidget13 className='mb-5 mb-xl-8' />
    </>
}